import { FC } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import { IDelivery, IInvoice, IOrder } from "interfaces";
import {
  calcOrdersTotalPriceWithDiscount,
  calcOrdersTotalWeight,
  calcTotalWeightOfEmptyContainers,
  removeTrailingZeroes,
} from "../utils";
import { localizeCurrency } from "helpers";

interface Props {
  delivery: IDelivery;
  invoices?: IInvoice[];
}

interface GridRow {
  clientId: number;
}

export const CargoManifest: FC<Props> = props => {
  const { delivery, invoices } = props;
  const { postal_operators, departure_nr, tenants, containers } = delivery;

  const packages = containers?.flatMap(container => container.packages);
  const allOrders = packages?.flatMap(pkg => pkg!.orders!);

  const sortedOrdersViaReceiver = (allOrders ?? [])?.reduce((total: { [key: string]: IOrder[] }, current) => {
    if (current) {
      const { client_id } = current;

      if (total[client_id]) {
        return { ...total, [client_id]: [...total[client_id], current] };
      } else {
        return { ...total, [client_id]: [current] };
      }
    }

    return total;
  }, {});

  const grid: Array<GridRow | undefined> = [
    ...Object.keys(sortedOrdersViaReceiver)
      .map(clientId => ({ clientId }))
      .sort((a, b) => {
        const containerA = packages?.find(pkg => pkg?.client_id === +a.clientId)?.container_id ?? 0;
        const containerB = packages?.find(pkg => pkg?.client_id === +b.clientId)?.container_id ?? 0;
        return containerB - containerA;
      }),
    ...(Array.apply(null, new Array(30 - (Object.keys(sortedOrdersViaReceiver).length ?? 0))) as any),
  ];

  const calcTotalWeightForRow = (orders: IOrder[], containerWeight: number) =>
    removeTrailingZeroes((calcOrdersTotalWeight(orders) + containerWeight / 1000).toFixed(3));

  const calcInvoiceTotalPrice = () =>
    packages
      ? localizeCurrency(
          packages.reduce((total, current) => {
            if (current) {
              const { orders, branch_id } = current;
              return (total += calcOrdersTotalPriceWithDiscount(orders ?? [], branch_id));
            }
            return total;
          }, 0)
        )
      : "";

  const calcTotalWeight = () => {
    const emptyContainersWeight = calcTotalWeightOfEmptyContainers(delivery.containers ?? []);
    const totalOrdersWeight = calcOrdersTotalWeight(allOrders ?? []);

    // TODO: Define the correct way.
    return totalOrdersWeight + emptyContainersWeight;
  };

  return (
    <Document title="Cargo Manifest">
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={styles.idents}>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.title}>COURIER COMPANY'S NAME AND ADRESS</Text>
                <Text style={styles.subTitle}>{tenants?.name}</Text>
                <Text style={styles.subTitle}>{tenants?.address}</Text>
                <Text style={styles.subTitle}>{`${tenants?.zipcode} ${tenants?.city}`}</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.title}>COURIER COMPANY'S NAME AND ADRESS</Text>
                <Text style={styles.subTitle}>{postal_operators?.name ?? ""}</Text>
                <Text
                  style={styles.subTitle}
                >{`${postal_operators?.destination_country}, ${postal_operators?.city}`}</Text>
                <Text style={styles.subTitle}>{postal_operators?.address ?? ""}</Text>
              </View>
            </View>
          </View>

          <View style={styles.documentName}>
            <Text style={styles.title}>CARGO MANIFEST</Text>
            <Text style={styles.title}>ГРУЗОВОЙ МАНИФЕСТ</Text>
          </View>

          <View style={styles.table}>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.subTitle}>
                  Name of documents in cargo manifest/Перечень сведений, указываемых в манифесте
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.subTitle}>{`Waybill name/Номер транспортной накладной: ${
                  departure_nr ?? ""
                }`}</Text>
              </View>
            </View>
            <View style={[styles.row, { height: 43 }]}>
              <View style={[styles.cell, styles.centeredView, styles.numberCell]}>
                <Text>№</Text>
              </View>
              <View style={[styles.cell, styles.centeredView, styles.invoiceNrCell]}>
                <Text>Parcel numbers</Text>
                <Text>Номера посылок</Text>
              </View>
              <View style={[styles.cell, styles.centeredView, styles.receiverCell]}>
                <Text>Receiver's name Ф,И,О,получателей:</Text>
              </View>
              <View style={[styles.cell, styles.centeredView, styles.passportCell]}>
                <Text>Passport number/ Серия и номер</Text>
                <Text>паспортов получателей:</Text>
              </View>
              <View style={[styles.cell, styles.centeredView, styles.weightCell]}>
                <Text>Gross weight/Вес</Text>
                <Text>брутто посылки</Text>
              </View>
              <View style={[styles.cell, styles.centeredView, styles.priceCell]}>
                <Text>Total price/Цена</Text>
                <Text>посылки</Text>
              </View>
            </View>
            {grid.map((row, index) => {
              const orders = row?.clientId ? sortedOrdersViaReceiver[row?.clientId] : undefined;
              const pkg = packages?.find(pkg => pkg?.client_id === +row?.clientId!);
              const container = containers?.find(container => container.id === pkg?.container_id);

              return (
                <View key={index} style={styles.row}>
                  <View style={[styles.cell, styles.centeredView, styles.numberCell]}>
                    <Text>{index + 1}</Text>
                  </View>
                  <View style={[styles.cell, styles.centeredView, styles.invoiceNrCell]}>
                    <Text>{container?.container_nr ?? ""}</Text>
                  </View>
                  <View style={[styles.cell, styles.centeredView, styles.receiverCell]}>
                    <Text>{pkg?.clients?.name ?? ""}</Text>
                  </View>
                  <View style={[styles.cell, styles.centeredView, styles.passportCell]}>
                    <Text>{pkg?.clients?.passport_nr ?? ""}</Text>
                  </View>
                  <View style={[styles.cell, styles.centeredView, styles.weightCell]}>
                    <Text>
                      {orders?.length
                        ? calcTotalWeightForRow(
                            orders,
                            (container?.weight ?? 0) / (container?.packages?.length ?? 1)
                          )
                        : ""}
                    </Text>
                  </View>
                  <View style={[styles.cell, styles.centeredView, styles.priceCell]}>
                    <Text>
                      {orders?.length && pkg
                        ? localizeCurrency(calcOrdersTotalPriceWithDiscount(orders, pkg?.branch_id))
                        : ""}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>

          <View style={[styles.flexRow, { marginBottom: 50, marginTop: 3 }]}>
            <View style={{ width: 277, alignItems: "flex-start" }}>
              <Text style={styles.boldText}>SIGNATURE AND STAMP/ПОДПИСЬ И ПЕЧАТЬ</Text>
            </View>
            <View style={[styles.centeredView, styles.passportCell]} />
            <View style={[styles.centeredView, styles.weightCell]}>
              <Text style={styles.boldText}>{allOrders?.length ? calcTotalWeight() : ""}</Text>
            </View>
            <View style={[styles.centeredView, styles.priceCell]}>
              <Text style={styles.boldText}>{calcInvoiceTotalPrice()}</Text>
            </View>
          </View>
          <View style={styles.flexRow}>
            <Text>
              Descriptions/Примечания: ______________________________________________________________
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 7,
    backgroundColor: "#FFFFFF",
  },
  idents: {
    flex: 1,
    margin: "56",
  },
  table: {
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: "black",
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderColor: "black",
  },
  cell: {
    alignItems: "center",
    borderRightWidth: 0.5,
    borderColor: "black",
    padding: 1,
    minHeight: 10,
  },
  flexRow: {
    flexDirection: "row",
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    paddingBottom: 2,
  },
  subTitle: {
    fontSize: 9,
    fontWeight: "bold",
  },
  documentName: {
    alignItems: "center",
    margin: 20,
  },
  centeredView: {
    alignItems: "center",
    justifyContent: "center",
  },
  numberCell: {
    width: 23,
  },
  invoiceNrCell: {
    width: 81,
  },
  receiverCell: {
    width: 168,
  },
  passportCell: {
    width: 158,
  },
  weightCell: {
    width: 70,
  },
  priceCell: {
    width: 73,
  },
  boldText: {
    fontWeight: "bold",
  },
});
