import { FC } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";

import { IDelivery, IContainer } from "interfaces";
import { calcContainerWeightInKilograms, calcTotalWeightOfContainersInKilograms } from "../utils";

interface Props {
  delivery: IDelivery;
}

export const CP87: FC<Props> = props => {
  const { delivery } = props;
  const { postal_operators, airdepartment_nr, departure_nr, flight_nr, shipment_date, containers, tenants } =
    delivery;

  const grid: IContainer[] = [
    ...(containers || []),
    ...(Array.apply(null, new Array(20 - (containers?.length ?? 0))) as any),
  ];

  return (
    <Document title="CP 87">
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={styles.idents}>
          <View style={styles.flexRow}>
            <View style={{ flex: 1 }}>
              <Text>Отправляющая Администрация</Text>
              <View style={{ minHeight: 10 }}>
                <Text style={styles.boldText}>{tenants?.own_postal_operator ?? ""}</Text>
              </View>
              <Text style={{ marginTop: 10 }}>Отправляющее Учреждение Обмена</Text>
              <Text style={styles.boldText}>{tenants?.name}</Text>
              <Text style={styles.boldText}>{tenants?.address}</Text>
              <Text style={styles.boldText}>{`${tenants?.zipcode} ${tenants?.city}`}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.title}>FUELLE DE ROUTE-AVION</Text>
              <View style={[styles.flexRow, { justifyContent: "space-between" }]}>
                <Text style={styles.title}>АВИАПОСЫЛОЧНАЯ КАРТА</Text>
                <Text style={styles.title}>CP 87</Text>
              </View>
              <Text>Colis-avion</Text>
              <View style={[styles.flexRow, { marginBottom: 10 }]}>
                <Text>Авиапосылки</Text>
                <Text style={[styles.boldText, { marginLeft: 50 }]}>{`№ ${departure_nr ?? ""}`}</Text>
              </View>
              <Text>Почтовые посылки авиа</Text>
              <View style={styles.flexRow}>
                <View style={[styles.flexRow, { flex: 1 }]}>
                  <Text style={[styles.boldText, { marginRight: 15 }]}>Дата отправки</Text>
                  <Text style={styles.boldText}>{shipment_date ? dayjs(shipment_date).format("L") : ""}</Text>
                </View>
                <View style={[styles.flexRow, { flex: 1 }]}>
                  <Text style={[styles.boldText, { marginRight: 15 }]}>№ депеши</Text>
                  <Text style={styles.boldText}>{airdepartment_nr ?? ""}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.table, { marginTop: 10 }]}>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text>Учреждение назначения</Text>
                <Text style={styles.boldText}>{postal_operators?.name}</Text>
                <Text
                  style={styles.boldText}
                >{`${postal_operators?.address} ${postal_operators?.city} ${postal_operators?.zipcode}`}</Text>
              </View>
              <View style={{ flex: 1.5 }}>
                <View style={styles.row}>
                  <View style={[styles.cell, { flex: 1, padding: 1 }]}>
                    <Text>Количество емкостей депеши</Text>
                  </View>
                  <View style={[styles.flexRow, { flex: 1, padding: 1 }]}>
                    <Text>Количество посылок в депеше</Text>
                    <View style={[styles.centered, { flex: 1 }]}>
                      <Text style={styles.boldText}>{containers?.length}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.flexRow}>
                  <View style={[styles.cell, { flex: 1, padding: 1 }]}>
                    <Text>Количество возврашаемых</Text>
                    <Text>емкостей</Text>
                  </View>
                  <View style={[{ flex: 1, padding: 1 }]}>
                    <Text>Количество посылок без</Text>
                    <Text>емкостей</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={{ flex: 1 }}>
                <View style={styles.flexRow}>
                  <View style={[styles.cell, { flex: 1 }]}></View>
                  <View style={[styles.cell, { flex: 4 }]}>
                    <View style={styles.row}>
                      <View style={[styles.flexRow, { flex: 2 }]}>
                        <View style={[styles.centered, { padding: 1 }]}>
                          <Text>№ рейса</Text>
                          <Text>S. A. L</Text>
                        </View>
                        <View style={[styles.centered, { marginLeft: 10 }]}>
                          <Text>{flight_nr ?? ""}</Text>
                        </View>
                      </View>
                      <View style={{ flex: 1, paddingRight: 1 }}>
                        <Text>бюллетень CN 44</Text>
                        <Text>прилагается к</Text>
                        <Text>депеше</Text>
                      </View>
                    </View>
                    <View style={[styles.flexRow, { justifyContent: "space-between", padding: 1 }]}>
                      <Text>Вес брутто депеши ---</Text>
                      <Text style={styles.boldText}>{calcTotalWeightOfContainersInKilograms(grid)}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ flex: 1.5 }}>
                <View style={styles.row}>
                  <View style={[styles.cell, { flex: 1 }]} />
                  <View style={{ flex: 1 }} />
                </View>
                <View style={[styles.flexRow, { flex: 1 }]}>
                  <View style={[styles.cell, { flex: 1, padding: 1 }]}>
                    <Text>Количество пустых емкостей</Text>
                  </View>
                  <View style={{ flex: 1, padding: 1 }}>
                    <Text>№ посылочной карты (если их</Text>
                    <Text>несколько</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Text style={[styles.boldText, { marginTop: 10 }]}>Поименная Запись</Text>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={[styles.cell, { width: 25 }]}>
                <View style={styles.tableHeader}>
                  <Text>№</Text>
                  <Text>п/п</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text />
                </View>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <View style={styles.tableHeader}>
                  <Text>№ посылки</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text>1</Text>
                </View>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <View style={styles.tableHeader}>
                  <Text>Учреждение</Text>
                  <Text>подачи</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text>2</Text>
                </View>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <View style={styles.tableHeader}>
                  <Text>Страна</Text>
                  <Text>назначения</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text>3</Text>
                </View>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <View style={styles.tableHeader}>
                  <Text>Poids</Text>
                  <Text>Bec KG</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text>4</Text>
                </View>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <View style={styles.tableHeader}>
                  <Text>Объявленная</Text>
                  <Text>ценость</Text>
                  <Text>Евро</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text>5</Text>
                </View>
              </View>
              <View style={[styles.cell, { minWidth: 110 }]}>
                <View style={[{ height: 58.5 }]}>
                  <View style={{ borderBottomWidth: 0.5, borderColor: "black", padding: 1 }}>
                    <Text>Доли, подлежащие оплате</Text>
                    <Text>Администрацией</Text>
                  </View>
                  <View style={[styles.flexRow, { flex: 1 }]}>
                    <View style={[styles.cell, { flex: 1, padding: 0, justifyContent: "space-between" }]}>
                      <View style={{ padding: 1 }}>
                        <Text>отправки</Text>
                        <Text>Администрации</Text>
                        <Text>отправки</Text>
                      </View>
                      <View style={styles.cellNumber}>
                        <Text>6</Text>
                      </View>
                    </View>
                    <View style={{ flex: 1, justifyContent: "space-between" }}>
                      <View>
                        <Text>назначения</Text>
                        <Text>Администрации</Text>
                        <Text>отправки</Text>
                      </View>
                      <View style={styles.cellNumber}>
                        <Text>7</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={[styles.cell, { borderRightWidth: 0 }]}>
                <View style={styles.tableHeader}>
                  <Text>Примечание</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text>8</Text>
                </View>
              </View>
            </View>
            {grid.map((row, index) => (
              <View key={index} style={styles.row}>
                <View style={[styles.cell, styles.centered, { width: 25 }]}>
                  {row && <Text>{index + 1}</Text>}
                </View>
                <View style={[styles.cell, { flex: 1 }]}>
                  <Text>{row?.container_nr}</Text>
                </View>
                <View style={[styles.cell, { flex: 1 }]}>
                  <Text style={styles.centered}>{row ? tenants?.departure_country ?? "" : ""}</Text>
                </View>
                <View style={[styles.cell, { flex: 1 }]}>
                  <Text style={styles.centered}>
                    {row ? postal_operators?.destination_country ?? "" : ""}
                  </Text>
                </View>
                <View style={[styles.cell, { flex: 1 }]}>
                  <Text style={styles.centered}>{row ? calcContainerWeightInKilograms(row) : ""}</Text>
                </View>
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, styles.flexRow, { width: 110 }]}>
                  <View style={[styles.cell, { flex: 1 }]} />
                  <View style={{ flex: 1 }} />
                </View>
                <View style={[styles.cell, { width: 43, borderRightWidth: 0 }]} />
              </View>
            ))}
          </View>
          <View style={[styles.flexRow, { marginTop: 10 }]}>
            <View style={{ width: 25 }} />
            <View style={[styles.table, { flex: 1 }]}>
              <View style={styles.row}>
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 3, padding: 1 }]}>
                  <Text>Итого ( лицевая сторона )</Text>
                </View>
                <View style={[styles.cell, styles.flexRow, { width: 110 }]}>
                  <View style={[styles.cell, { flex: 1 }]} />
                  <View style={{ flex: 1 }} />
                </View>
                <View style={[styles.cell, { width: 43, borderRightWidth: 0 }]} />
              </View>
              <View style={styles.row}>
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 3, padding: 1 }]}>
                  <Text>Перенос с обратной стороны</Text>
                </View>
                <View style={[styles.cell, styles.flexRow, { width: 110 }]}>
                  <View style={[styles.cell, { flex: 1 }]} />
                  <View style={{ flex: 1 }} />
                </View>
                <View style={[styles.cell, { width: 43, borderRightWidth: 0 }]} />
              </View>
              <View style={styles.row}>
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 3, padding: 1 }]}>
                  <Text>Итого</Text>
                </View>
                <View style={[styles.cell, styles.flexRow, { width: 110 }]}>
                  <View style={[styles.cell, { flex: 1 }]} />
                  <View style={{ flex: 1 }} />
                </View>
                <View style={[styles.cell, { width: 43, borderRightWidth: 0 }]} />
              </View>
            </View>
          </View>
          <Text style={[styles.boldText, { marginLeft: 26 }]}>
            Запись общим счетом (посылки для страны назначения )
          </Text>
          <View style={styles.flexRow}>
            <View style={{ width: 25 }} />
            <View style={[styles.table, { flex: 1 }]}>
              <View style={styles.row}>
                <View style={[styles.cell, { flex: 3 }]}>
                  <View style={[styles.flexRow, { flex: 1, padding: 1 }]}>
                    <Text>Общее количество контейнеров</Text>
                    <View style={[styles.centered, { flex: 1 }]}>
                      <Text style={styles.boldText}>{containers?.length}</Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.cell, { flex: 2 }]}>
                  <View style={[styles.row, { justifyContent: "space-between", padding: 1 }]}>
                    <Text style={styles.boldText}>Вес брутто</Text>
                    <Text style={styles.boldText}>Итого сумма</Text>
                  </View>
                  <View style={styles.flexRow}>
                    <View style={[styles.cell, { flex: 1, padding: 1 }]}>
                      <Text style={styles.boldText}>{calcTotalWeightOfContainersInKilograms(grid)}</Text>
                    </View>
                    <View style={{ flex: 1 }} />
                  </View>
                </View>
                <View style={[styles.cell, styles.flexRow, { width: 110, padding: 1 }]}>
                  <Text>Причитающиеся долги</Text>
                </View>
                <View style={[styles.cell, { width: 43, borderRightWidth: 0, padding: 1 }]}>
                  <Text>Примечания </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.flexRow, { marginTop: 30 }]}>
            <View style={{ width: 25 }} />
            <View style={{ flex: 1 }}>
              <Text>Отправляющее учреждение обмена</Text>
              <Text>Дата и подпись</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text>Учреждение обмена назначения</Text>
              <Text>Дата и подпись</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 7,
  },
  idents: {
    flex: 1,
    margin: "56",
  },
  flexRow: {
    flexDirection: "row",
  },
  title: {
    fontSize: 10,
    fontWeight: "bold",
    paddingBottom: 2,
  },
  boldText: {
    fontWeight: "bold",
  },
  table: {
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: "black",
  },
  tableHeader: {
    height: 50,
    padding: 1,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderColor: "black",
  },
  cell: {
    borderRightWidth: 0.5,
    borderColor: "black",
    minHeight: 10,
  },
  centered: {
    alignItems: "center",
    justifyContent: "center",
  },
  cellNumber: {
    borderTopWidth: 0.5,
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
  },
});
