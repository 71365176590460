import {
  calcOrdersTotalPriceWithDiscount,
  calcOrdersTotalWeight,
  calcTotalWeightOfEmptyContainers,
  groupSimilarOrders,
  removeTrailingZeroes,
} from "documents/utils";
import { localizeCurrency } from "helpers";
import { IContainer, IDelivery, IOrder, IPackage } from "interfaces";

export const prepareInvoiceDataForExcel = (
  delivery: IDelivery | undefined,
  packages: (IPackage | undefined)[] | undefined
): { csvData: any[]; headers: { label: string; key: string }[] } => {
  const allOrders = packages?.flatMap(pkg => pkg!.orders!);

  const containerWeight = (orders: IOrder[] | undefined, containerWeight: number | undefined) => {
    const ordersWeight = calcOrdersTotalWeight(orders ?? []);
    const total = ordersWeight + (containerWeight ?? 0) / 1000;

    return total > 0 ? removeTrailingZeroes(total.toFixed(3)) : "";
  };

  const calcInvoiceTotalPrice = () =>
    packages
      ? localizeCurrency(
          packages.reduce((total, current) => {
            if (current) {
              const { orders, branch_id } = current;
              return (total += calcOrdersTotalPriceWithDiscount(orders ?? [], branch_id));
            }
            return total;
          }, 0)
        )
      : "";

  const totalWeight = () => {
    const ordersWeight = calcOrdersTotalWeight(allOrders ?? []) ?? 0;
    const emptyContainersWeight = calcTotalWeightOfEmptyContainers(delivery?.containers ?? []) ?? 0;

    const total = ordersWeight + emptyContainersWeight;

    return total > 0 ? removeTrailingZeroes(total.toFixed(3)) : "";
  };

  const preparedData = (delivery?.containers ?? []).reduce(
    (acc: any, { weight, packages, container_nr }: IContainer) => [
      ...acc,
      ...(packages ?? []).flatMap(pkg => [
        {
          container_nr,
          article_nr: `${pkg.clients?.name}, ${pkg.clients?.city}, ${pkg.clients?.address}, ${pkg.clients?.zipcode}`,
          weight: containerWeight(pkg.orders, (weight ?? 0) / (packages?.length ?? 1)),
          sum: localizeCurrency(calcOrdersTotalPriceWithDiscount(pkg.orders ?? [], pkg.branch_id)),
          passport_nr: pkg.clients?.passport_nr,
        },
        ...groupSimilarOrders(pkg.orders ?? []).map(({ article_number, items, name, short_name }) => ({
          article_nr: article_number ?? "",
          amount: items.length ?? "",
          name: short_name ?? name,
          price: localizeCurrency(+calcOrdersTotalPriceWithDiscount(items, pkg.branch_id)) ?? "",
        })),
      ]),
    ],
    []
  );

  return {
    csvData: [
      ...preparedData,
      {
        article_nr: `Посылок всего: ${packages?.length ?? ""}`,
        weight: `Всего кг: ${totalWeight()}`,
        sum: `Общая сумма: ${calcInvoiceTotalPrice()}`,
      },
    ],
    headers: [
      {
        label: "№ посылки",
        key: "container_nr",
      },
      {
        label: "Номер Артикула по каталогу",
        key: "article_nr",
      },
      {
        label: "Кол-во (шт.)",
        key: "amount",
      },
      {
        label: "Наименование товара",
        key: "name",
      },
      {
        label: "Вес (брутто) кг",
        key: "weight",
      },
      {
        label: "Цена по каталогу",
        key: "price",
      },
      {
        label: "Сумма €",
        key: "sum",
      },
      {
        label: "Номер паспорта",
        key: "passport_nr",
      },
    ],
  };
};
