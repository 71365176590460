import { FC } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { IDelivery, IOrder } from "interfaces";
import dayjs from "dayjs";

import {
  calcOrdersTotalWeight,
  calcOrdersTotalPriceWithDiscount,
  calcTotalWeightOfEmptyContainers,
  removeTrailingZeroes,
  groupSimilarOrders,
} from "../utils";
import { localizeCurrency } from "helpers";

interface Props {
  delivery: IDelivery;
}

/**
 * In case of Dmitry's workflow the document should display information about container.
 * 1 container includes only 1 package.
 * The document available only for Dmitri Zwetkow Logistik tenant.
 */
export const RussianInvoice: FC<Props> = props => {
  const { delivery } = props;
  const { airdepartment_nr, tenants, shipment_date, delivery_nr, containers } = delivery;

  const packages = containers?.flatMap(container => container.packages);
  const allOrders = packages?.flatMap(pkg => pkg!.orders!);

  /** This price doesn't includes cost per shipping weight and cost for shipping from the shops. */
  const calcInvoiceTotalPrice = () =>
    packages
      ? localizeCurrency(
          packages.reduce((total, current) => {
            if (current) {
              const { orders, branch_id } = current;
              return (total += calcOrdersTotalPriceWithDiscount(orders ?? [], branch_id));
            }
            return total;
          }, 0)
        )
      : "";

  const totalWeight = () => {
    const ordersWeight = calcOrdersTotalWeight(allOrders ?? []) ?? 0;
    const emptyContainersWeight = calcTotalWeightOfEmptyContainers(delivery.containers ?? []) ?? 0;

    const total = ordersWeight + emptyContainersWeight;

    return total > 0 ? removeTrailingZeroes(total.toFixed(3)) : "";
  };

  const containerWeight = (orders: IOrder[] | undefined, containerWeight: number | undefined) => {
    const ordersWeight = calcOrdersTotalWeight(orders ?? []);

    const total = ordersWeight + (containerWeight ?? 0) / 1000;

    return total > 0 ? removeTrailingZeroes(total.toFixed(3)) : "";
  };

  return (
    <Document title="Russian Invoice">
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.title}>{`INVOICE № ${delivery_nr} (СЧЁТ-ПРОФОРМА)ИНВОЙС`}</Text>
          <Text style={styles.boldText}>{`Отправитель: ${tenants?.name}`}</Text>
          <Text style={styles.boldText}>{`Дата отправки: ${
            shipment_date ? dayjs(shipment_date).format("L") : ""
          }`}</Text>
          <Text style={styles.boldText}>{`Номер депеши: ${airdepartment_nr ?? ""}`}</Text>
        </View>
        <View style={styles.table}>
          <View style={[styles.row, styles.headerRow]}>
            <View style={[styles.cell, { width: 60 }]}>
              <Text style={styles.boldText}>№ посылки</Text>
            </View>
            <View style={[styles.cell, { width: 70 }]}>
              <Text style={styles.boldText}>Номер Артикула</Text>
              <Text style={styles.boldText}>по каталогу</Text>
            </View>
            <View style={[styles.cell, { width: 30 }]}>
              <Text style={styles.boldText}>Кол-во</Text>
              <Text style={styles.boldText}>(шт.)</Text>
            </View>
            <View style={[styles.cell, { flex: 1 }]}>
              <Text style={styles.boldText}>Наименование товара</Text>
            </View>
            <View style={[styles.cell, { width: 30 }]}>
              <Text style={styles.boldText}>Вес</Text>
              <Text style={styles.boldText}>(брутто)</Text>
              <Text style={styles.boldText}>кг</Text>
            </View>
            <View style={[styles.cell, { width: 45 }]}>
              <Text style={styles.boldText}>Цена</Text>
              <Text style={styles.boldText}>по каталогу</Text>
            </View>
            <View style={[styles.cell, { width: 30 }]}>
              <Text style={styles.boldText}>Сумма</Text>
              <Text style={styles.boldText}>€</Text>
            </View>
            <View style={[styles.cell, { width: 40 }]}>
              <Text style={styles.boldText}>Номер паспорта</Text>
            </View>
          </View>
          {containers?.map((container, index) => {
            const { weight, packages, container_nr } = container;
            const containerWeightPerPackage = (weight ?? 0) / (packages?.length ?? 1);

            return (
              <>
                {packages?.map((pkg, pkgIndex) => (
                  <View key={`${index}-${pkgIndex}`}>
                    <View style={[styles.row, styles.clientRow]}>
                      <View style={[styles.cell, { width: 60 }]}>
                        <Text>{container_nr ?? ""}</Text>
                      </View>
                      <View style={[styles.cell, { width: 70 }]}>
                        {pkg.clients && (
                          <Text>{`${pkg.clients.name}, ${pkg.clients.city}, ${pkg.clients.address}, ${pkg.clients.zipcode}`}</Text>
                        )}
                      </View>
                      <View style={[styles.cell, { width: 30 }]} />
                      <View style={[styles.cell, { flex: 1 }]} />
                      <View style={[styles.cell, { width: 30 }]}>
                        <Text>{containerWeight(pkg.orders, containerWeightPerPackage)}</Text>
                      </View>
                      <View style={[styles.cell, { width: 45 }]} />
                      <View style={[styles.cell, { width: 30 }]}>
                        <Text>
                          {localizeCurrency(
                            calcOrdersTotalPriceWithDiscount(pkg.orders ?? [], pkg.branch_id)
                          )}
                        </Text>
                      </View>
                      <View style={[styles.cell, { width: 40 }]}>
                        <Text>{pkg.clients?.passport_nr}</Text>
                      </View>
                    </View>
                    {pkg.orders &&
                      groupSimilarOrders(pkg.orders).map((group, groupIndex) => {
                        const { article_number, items, name, short_name } = group;
                        return (
                          <View key={`${index}-${pkgIndex}-${groupIndex}`} style={styles.row}>
                            <View style={[styles.cell, { width: 60 }]} />
                            <View style={[styles.cell, { width: 70, alignItems: "flex-start" }]}>
                              <Text>{article_number ?? ""}</Text>
                            </View>
                            <View style={[styles.cell, { width: 30 }]}>
                              <Text>{items.length}</Text>
                            </View>
                            <View style={[styles.cell, { flex: 1, alignItems: "flex-start" }]}>
                              <Text>{short_name ?? name}</Text>
                            </View>
                            <View style={[styles.cell, { width: 30 }]} />
                            <View style={[styles.cell, { width: 45 }]}>
                              <Text>
                                {localizeCurrency(+calcOrdersTotalPriceWithDiscount(items, pkg.branch_id))}
                              </Text>
                            </View>
                            <View style={[styles.cell, { width: 30 }]} />
                            <View style={[styles.cell, { width: 40 }]} />
                          </View>
                        );
                      })}
                  </View>
                ))}
              </>
            );
          })}
          <View style={[styles.row, styles.cell, { justifyContent: "space-around", paddingVertical: 7 }]}>
            <View>
              <Text style={styles.boldText}>{`Посылок всего: ${packages?.length ?? ""}`}</Text>
            </View>
            <View>
              <Text style={styles.boldText}>{`Всего кг: ${totalWeight()}`}</Text>
            </View>
            <View>
              <Text style={styles.boldText}>{`Общая сумма: ${calcInvoiceTotalPrice()}`}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 7,
    paddingHorizontal: 84,
    paddingVertical: 56,
  },
  boldText: {
    fontWeight: "bold",
  },
  title: {
    fontSize: 10,
    fontWeight: "bold",
    paddingBottom: 2,
  },
  table: {
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: "black",
    marginTop: 20,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderColor: "black",
  },
  cell: {
    padding: 1,
    minHeight: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRightWidth: 0.5,
    borderColor: "black",
  },
  headerRow: {
    backgroundColor: "#C0C0C0",
  },
  clientRow: {
    backgroundColor: "#E9E9E9",
  },
});
