import { FC } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { IBranch, IContainer, IDelivery, IOrder, IPackage } from "interfaces";
import dayjs from "dayjs";
import {
  calcOrdersTotalWeight,
  calcShippingCostFromTheShops,
  calcShippingCostOfTotalWeight,
  calcOrdersTotalPriceWithDiscountInRechnung,
  removeTrailingZeroes,
  groupSimilarOrders,
} from "../utils";
import { localizeCurrency } from "helpers";
import { Button } from "@pankod/refine-antd";
import { DocumentIcon } from "assets";
import { useTranslate } from "@pankod/refine-core";

export interface CommercialInvoiceProps {
  delivery: IDelivery;
  invoiceNr: string;
  orders: IOrder[];
  branch: IBranch;
}

export const CommercialInvoice: FC<CommercialInvoiceProps> = props => {
  const t = useTranslate();
  const {
    delivery: { tenants, delivery_nr, departure_nr, containers, mrn_nr },
    invoiceNr,
    orders,
    branch,
  } = props;

  const orderGroups = groupSimilarOrders(orders!);

  const ordersTotalPrice = localizeCurrency(
    orders.reduce((total: number, current) => total + current.arrival_price, 0)
  );

  const packages =
    (containers ?? []).flatMap((container: IContainer) =>
      container.packages?.map((pkg: IPackage) => ({
        ...pkg,
        containerWeight: (container.weight ?? 0) / (container.packages?.length ?? 1),
      }))
    ) || [];

  const containerWeight = packages
    .filter((pkg: any) => pkg.branch_id === branch?.id)
    // Works only in case of 1 container has 1 package.
    .reduce((total: number, pkg) => (total += pkg?.containerWeight ?? 0), 0);

  const totalWeight = (orders: IOrder[]) => {
    const ordersWeight = calcOrdersTotalWeight(orders);
    return removeTrailingZeroes((ordersWeight + (containerWeight ?? 0) / 1000).toFixed(3));
  };

  const totalShippingCosts =
    calcShippingCostFromTheShops(orders, branch) +
    calcShippingCostOfTotalWeight(orders, branch, containerWeight);

  const priceNetto = calcOrdersTotalPriceWithDiscountInRechnung(orders, branch.id) + totalShippingCosts;

  const doc = (
    <Document title="Commercial Invoice">
      <Page size="A4" style={styles.page}>
        <View>
          <View style={[styles.header, styles.flexRow]}>
            <View style={{ flex: 2 }}>
              <Text style={styles.registrationNumber}>MRN: {mrn_nr ?? ""}</Text>
              <Text>{`${tenants?.name} ${tenants?.address} ${tenants?.zipcode} ${tenants?.city}`}</Text>
            </View>
            {tenants?.logo && (
              <View style={{ flex: 1, alignItems: "flex-end" }}>
                <Image src={tenants?.logo} style={styles.logo} />
              </View>
            )}
          </View>

          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Text>{branch?.name ?? ""}</Text>
              <Text>{branch?.address ?? ""}</Text>
              <Text>{`${branch?.zipcode ?? ""} ${branch?.city ?? ""}`}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text>{`Datum: ${dayjs().format("L")}`}</Text>
              <Text>{`Lieferung: ${delivery_nr}`}</Text>
              <Text>{`Lieferdatum: ${dayjs().format("L")}`}</Text>
              <Text>{`Kunde-Nr: ${branch?.branch_nr ?? ""}`}</Text>
            </View>
          </View>

          <View>
            <Text style={styles.title}>{`Rechnung ${invoiceNr}`}</Text>
          </View>

          <View>
            <View style={[styles.row, styles.headerRow]}>
              <View style={[styles.cell, styles.fixedCell, { flex: 3, alignItems: "flex-start" }]}>
                <Text>Bezeichnung</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 2 }]}>
                <Text>Artikelnummer</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]}>
                <Text>Menge</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]}>
                <Text>EPreis</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]}>
                <Text>Rabatt(%)</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]}>
                <Text>Betrag</Text>
              </View>
            </View>

            {orderGroups.map((group, index) => {
              const { name, article_number, items } = group;
              const { arrival_price, shops } = items[0];

              const discount =
                shops?.branch_shops?.find(branch_shop => branch_shop.branch_id === branch.id)?.discount ?? "";

              return (
                <View key={index} style={styles.row}>
                  <View style={[styles.cell, { flex: 3, alignItems: "flex-start" }]}>
                    <Text>{name}</Text>
                  </View>
                  <View style={[styles.cell, { flex: 2 }]}>
                    <Text>{article_number}</Text>
                  </View>
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text>{items.length}</Text>
                  </View>
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text>{arrival_price}</Text>
                  </View>
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text>{discount}</Text>
                  </View>
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text>{calcOrdersTotalPriceWithDiscountInRechnung(items, branch.id)}</Text>
                  </View>
                </View>
              );
            })}
            {/* // TODO: Not needed at this moment */}
            {/* <View style={styles.row}>
              <View style={[styles.cell, styles.fixedCell, { flex: 3 }]} />
              <View style={[styles.cell, styles.fixedCell, { flex: 2 }]}>
                <Text style={styles.boldText}>Skonto von Vorzahlung</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]}>
                <Text style={styles.boldText}>{"3%"}</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]}>
                <Text>{`${ordersTotalPriceWithDiscount.toFixed(2)} €`}</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]}>
                <Text style={styles.boldText}>{`0.00 €`}</Text>
              </View>
            </View> */}

            <View style={styles.row}>
              <View style={[styles.cell, styles.fixedCell, { flex: 3, alignItems: "flex-start" }]}>
                <Text style={styles.boldText}>Summe</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 2 }]} />
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]}>
                <Text style={styles.boldText}>{orders.length}</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]}>
                <Text style={styles.boldText}>{ordersTotalPrice}</Text>
              </View>
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]} />
              <View style={[styles.cell, styles.fixedCell, { flex: 1 }]}>
                <Text style={styles.boldText}>
                  {localizeCurrency(calcOrdersTotalPriceWithDiscountInRechnung(orders, branch.id))}
                </Text>
              </View>
            </View>
          </View>
          <View wrap={false} style={styles.weightInfo}>
            <View style={{ flex: 2 }}>
              <View style={styles.flexRow}>
                <View style={{ flex: 1 }}>
                  {/* <Text style={styles.boldText}>Katalogversandkosten</Text> */}
                </View>
                <View style={{ flex: 1, paddingLeft: 20 }}>
                  <Text style={styles.boldText}>Warenversandkosten</Text>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={[styles.flexRow, styles.leftRow]}>
                  {/* <Text>Bruttogewicht(Kg):</Text>
                  <Text style={styles.TODO}>0.000</Text> */}
                </View>
                <View style={[styles.flexRow, styles.rightRow]}>
                  <Text>Gewicht(Kg):</Text>
                  <Text>{totalWeight(orders)}</Text>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={[styles.flexRow, styles.leftRow]}>
                  {/* <Text>Preis(pro Kg):</Text>
                  <Text style={styles.TODO}>0.00€</Text> */}
                </View>
                {/* <View style={[styles.flexRow, styles.rightRow]}>
                  <Text>Bruttogewicht(Kg):</Text>
                  <Text style={styles.TODO}>10.980</Text>
                </View> */}
              </View>
              <View style={styles.flexRow}>
                <View style={{ flex: 1 }} />
                <View style={[styles.flexRow, { flex: 1, paddingLeft: 20 }]}>
                  <Text>Preis(pro Kg):</Text>
                  <Text>{localizeCurrency(branch?.shipping_cost_per_kg)}</Text>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={[styles.flexRow, styles.leftRow]}>
                  {/* <Text style={styles.boldText}>Gesamt:</Text>
                  <Text style={[styles.boldText, styles.TODO]}>0.00€</Text> */}
                </View>
                <View style={[styles.flexRow, styles.rightRow]}>
                  <Text style={styles.boldText}>Gesamt:</Text>
                  <Text style={styles.boldText}>
                    {localizeCurrency(calcShippingCostOfTotalWeight(orders, branch, containerWeight))}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={[styles.flexRow, { flex: 1 }]} />
              <View style={[styles.flexRow, { flex: 1 }]} />
              <View style={[styles.flexRow, { flex: 1 }]} />
              {/* <View style={[styles.flexRow, { flex: 1 }]} /> */}
              <View style={[styles.flexRow, styles.rightRow]}>
                <Text style={styles.boldText}>Paketzustellungskosten:</Text>
                <Text style={styles.boldText}>
                  {localizeCurrency(calcShippingCostFromTheShops(orders, branch))}
                </Text>
              </View>
              <View style={[styles.flexRow, styles.rightRow]}>
                <Text style={styles.boldText}>Gesamtversandkosten:</Text>
                <Text style={styles.boldText}>{localizeCurrency(totalShippingCosts)}</Text>
              </View>
            </View>
          </View>
          <View wrap={false} style={styles.flexRow}>
            <View style={{ flex: 2 }}>
              <Text style={styles.boldText}>Fracht</Text>
              <Text style={styles.boldText}>{`AWB: ${departure_nr ?? ""}`}</Text>
              <Text style={styles.boldText}>Steuerfreie Ausfuhrlieferung gem. § 4 Abs 1 Nr 1a UStG</Text>
            </View>
            <View style={{ flex: 1 }}>
              <View style={[styles.flexRow, styles.rightRow]}>
                <Text style={styles.boldText}>Netto:</Text>
                <Text style={styles.boldText}>{localizeCurrency(priceNetto)}</Text>
              </View>
              <View style={[styles.flexRow, styles.rightRow]}>
                <Text style={styles.boldText}>19% MwSt:</Text>
                <Text style={styles.boldText}>0.00 €</Text>
              </View>
              <View style={[styles.flexRow, styles.rightRow]}>
                <Text style={styles.boldText}>Gesamtbetrag:</Text>
                <Text style={styles.boldText}>{localizeCurrency(priceNetto)}</Text>
              </View>
            </View>
          </View>
        </View>

        <View fixed style={styles.footer}>
          <View style={{ flex: 1 }}>
            <Text>{tenants?.name}</Text>
            <Text>{tenants?.address}</Text>
            <Text>{`${tenants?.zipcode} ${tenants?.city}`}</Text>
            {tenants?.country && <Text>{tenants?.country}</Text>}
          </View>
          <View style={{ flex: 1 }}>
            {tenants?.phone && <Text>Tel.: {tenants?.phone}</Text>}
            <Text>E-Mail: {tenants?.email}</Text>
            {tenants?.jurisdiction && <Text>Gerichtsstand: {tenants?.jurisdiction}</Text>}
            {tenants?.local_court && <Text>Amtsgericht: {tenants?.local_court}</Text>}
            {tenants?.tax_number && <Text>Steuernummer: {tenants?.tax_number}</Text>}
            {tenants?.vat_id && <Text>USt-IdNr: {tenants?.vat_id}</Text>}
          </View>
          <View style={{ flex: 1 }}>
            {tenants?.bank_name && <Text>{tenants?.bank_name}</Text>}
            {tenants?.bic && <Text>BIC: {tenants?.bic}</Text>}
            {tenants?.iban && <Text>IBAN: {tenants?.iban}</Text>}
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <PDFViewer
        style={{
          paddingTop: 32,
          width: "100%",
          height: "80vh",
          border: "none",
        }}
      >
        {doc}
      </PDFViewer>
      <div
        style={{
          width: "100px",
        }}
      >
        <PDFDownloadLink document={doc!} fileName={`CommercialInvoice_#${invoiceNr}`}>
          <Button
            icon={<DocumentIcon style={{ marginRight: 4 }} />}
            style={{ display: "flex", alignItems: "center" }}
          >
            {t("buttons.save")}
          </Button>
        </PDFDownloadLink>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 7,
    paddingHorizontal: 84,
    paddingVertical: 56,
    justifyContent: "space-between",
  },
  header: {
    paddingBottom: 5,
    marginBottom: 5,
    borderBottomColor: "black",
    borderBottomWidth: 1,
  },
  registrationNumber: {
    fontSize: 12,
    fontWeight: "bold",
    paddingBottom: 5,
  },
  logo: {
    height: 25,
    minWidth: 25,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    paddingVertical: 2,
  },
  headerRow: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "black",
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderColor: "black",
  },
  boldText: {
    fontWeight: "bold",
  },
  cell: {
    padding: 1,
    minHeight: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  fixedCell: {
    height: 20,
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  weightInfo: {
    flexDirection: "row",
    marginVertical: 5,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderColor: "black",
  },
  leftRow: {
    flex: 1,
    paddingRight: 10,
  },
  rightRow: {
    flex: 1,
    paddingLeft: 10,
  },
  footer: {
    flexDirection: "row",
    borderTopWidth: 0.5,
    borderColor: "black",
    marginTop: 5,
    paddingTop: 5,
  },
  TODO: {
    color: "red",
  },
});
